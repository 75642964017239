import { Component, OnInit } from '@angular/core';
import { DatosService } from '../../services/datos.service';

@Component({
  selector: 'app-aviso',
  templateUrl: './aviso.component.html'

})


export class AvisoComponent implements OnInit {

  constructor( public _datosService:DatosService) { }

  ngOnInit() {
  }

}
