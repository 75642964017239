import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-ayuda',
  templateUrl: './ayuda.component.html'
})
export class AyudaComponent implements OnInit {

  constructor( private title:Title, private meta:Meta) { 
    this.title.setTitle('SAD, Servicio de Ayuda a Domicilio en Valladolid');
    this.meta.updateTag({ name: 'title', content: 'SAD, Servicio de Ayuda a Domicilio en Valladolid'});
    this.meta.updateTag({ name: 'description', content: 'La Residencia Nuestra Señora del Rosario en Valladolid pone a su disposición el servicio de ayuda a domicilio SAD. Para que pueda disfrutar de las ventajas de una residencia de calidad desde su domicilio.'});
  }

  ngOnInit() {
  }

}
