import { Component, OnInit } from '@angular/core';
import { DatosService } from '../../services/datos.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  vistaApartado: boolean = false;
  apartadosSecundarios: any = ['aviso-legal', 'politica-de-privacidad', 'politica-de-cookies', 'normas-uso-de-rrss'];
  constructor(public _datosService: DatosService) { }

  ngOnInit() {
    this.sacaApartado();
  }

  sacaApartado() {
    setTimeout(() => {
      this.vistaApartado = true;
    }, 100);
  }

}
