import { Component, OnInit } from '@angular/core';
import { DatosService } from '../../services/datos.service';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-dinamica',
  templateUrl: './dinamica.component.html'
})
export class DinamicaComponent implements OnInit {
  vistaApartado: boolean = false;
  apartado: string = "";
  texto: any[];
  texto_en: any[];
  texto_fr: any[];
  ruta: string = "";

  constructor(public _datosService: DatosService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let ruta = this.router.routerState.snapshot.root.firstChild.url[0].path;
        this.ruta = ruta;

        if (ruta == 'politica-de-cookies') {
          this.apartado = 'legal_politica_de_cookies';
        }
        if (ruta == 'politica-de-privacidad') {
          this.apartado = 'legal_politica_de_privacidad';
        }
        if (ruta == 'aviso-legal') {
          this.apartado = 'legal_aviso_legal';
        }
        if (ruta == 'normas-uso-de-rrss') {
          this.apartado = 'legal_normas_uso';
        }

        this.cargaContenido(this.apartado);
      }

    });
  }

  ngOnInit() {

    this._datosService.ponSeccion('dinamica');
    this.vistaApartado = false;
    this.sacaApartado();
  }

  sacaApartado() {
    setTimeout(() => {
      this.vistaApartado = true;
    }, 1500);


  }


  cargaContenido(ruta) {
    this._datosService.getTexto(ruta).subscribe(datos => {
      this.texto = datos.text_1;
    });

    let rutaIngles = ruta + "_en";
    this._datosService.getTexto(rutaIngles).subscribe(datos => {
      this.texto_en = datos.text_1;
    });

    let rutaFrances = ruta + "_fr";
    this._datosService.getTexto(rutaIngles).subscribe(datos => {
      this.texto_fr = datos.text_1;
    });
  }

}
