export class EmpleoForm{
    public name:string;
    public telefono:string;
    public provincia:string;
    public email:string;
    public subject:string;
    public msn:string;
    public file:any;
    public aviso:boolean = false;
}
