import { Component, OnInit } from '@angular/core';
import { DatosService } from '../../services/datos.service';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute} from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  vistaApartado:boolean = false;

  slides:any = [];
  slide:any = [];
  trabajo: any[];
  productos: any[];


  espera: number = 8000;
  indexActual:number;
  interval;
  active: boolean = false;
  ampliacion: boolean = false;

  galeria:any[];
  titulos:any[];


  constructor(public _datosService:DatosService, private title:Title, private meta:Meta) {
    this.title.setTitle('Residencia de Ancianos en el Centro de Valladolid, Nuestra Señora del Rosario');
    this.meta.updateTag({ name: 'title', content: 'Residencia de Ancianos en el Centro de Valladolid, Nuestra Señora del Rosario'});
    this.meta.updateTag({ name: 'description', content: 'La residencia de ancianos Nuestra Señora del Rosario en el centro de Valladolid cuenta con un equipo multidisciplinar altamente cualificado para ofrecer a sus residentes la mejor atención personalizada.'});
  }


  ngOnInit() {
    this._datosService.getProductos().subscribe(datos => {
        this.slides = datos;
        this.slide = this.slides.filter((slide)=> slide.url == 'home');
        this.slide = this.slide[0];

        //meto en titulo el arry galeria sin haberlo limpiado
        this.titulos = this.slide.galeria;


        //ORDENO POR POSITION
        this.slide.galeria.sort((val1, val2)=> {return val2.position - val1.position});


        //saco del objeto galeria las entradas
        let galeria = this.slide['galeria'].map((foto) => foto.imagenes);
        let arrGaleria = Object.keys(galeria).map(function(foto){
          let laFoto = galeria[foto][0];
          return laFoto;
        });


        //concateno array imagenes con array galeria ya limpio
        this.slide['imagenes'] = []; //vacio primero las imagenes porque conserva la principal que no procede en este proyecto



        this.galeria = this.slide['imagenes'].concat(arrGaleria);



        this.galeria = this.galeria.map((foto) => foto.path );
        this.indexActual = 0;
        setTimeout(() => { this.active = true; this.getNext(); }, 500);

        //console.log(this.galeria);

        this.vistaApartado = false;
        this.sacaApartado();
      });

    this._datosService.ponSeccion('home');


}


//FUNCIONES DE SLIDE DE HOME
  getNext() {
    this.interval = setInterval (() => {
        if(this.indexActual == this.galeria.length - 1) {
          this.indexActual = 0;
        }
        else {
          this.indexActual++;
        }
        //this.foto = this.trabajo['imagenes'][this.indexActual];
    }, this.espera);
  }

  hayFotos() {
    if(this.galeria.length > 2) {
      return true;
    }
  }

  getCampo(dato) {
    return (this.trabajo[this._datosService.getCampo(dato)]);
  }


  anterior() {
    if(this.indexActual == 0) {
      this.indexActual = this.galeria.length - 1;
    }
    else {
      this.indexActual--;
    }
  }

  siguiente() {
    if(this.indexActual == this.galeria.length - 1) {
      this.indexActual = 0;
    }
    else {
      this.indexActual++;
    }
  }



  sacaApartado() {
    setTimeout(() => {
      this.vistaApartado = true;
    }, 100);
  }


}
