import { Component,  OnInit, ViewChild, Input } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { TranslateService } from 'ng2-translate';
import { DatosService } from '../../services/datos.service';
import { Router, Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromEvent';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  currentLang:any = '';
  apartados:any = [{name: 'residencia', link: ''}, {name: 'instalaciones', link: 'instalaciones'}, {name: 'ayuda-a-domicilio', link: 'ayuda-a-domicilio'}, {name: 'localizacion-y-contacto', link: 'localizacion-y-contacto'}, {name: 'blog', link: 'blog'}, {name: 'empleo', link: 'empleo'}];
  apartadosSecundarios:any = ['aviso-legal', 'politica-de-privacidad', 'politica-de-cookies'];
  private categorias:any[];

  constructor(private activatedRoute:ActivatedRoute, private router: Router, public _datosService:DatosService,private translate: TranslateService) { }

  ngOnInit() {
    this._datosService.getCategorias().subscribe(datos => {
      this.categorias = datos;
    });



  }

  getLang() {
    if(this.currentLang) {
      return this.currentLang;
    }
    else{
      return this.translate.getBrowserLang();
    }
  }

  gotoCarrito() {

  }

}
