import { Component, OnInit, HostListener, Inject, ViewChild, Renderer2 } from '@angular/core';
import { DatosService } from '../../services/datos.service';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute} from '@angular/router';
import { Directive, ElementRef,Renderer} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { FormControl, Validators, EmailValidator } from '@angular/forms';
import { ContactForm } from '../../models/contactForm';
//libreria rxjs
import {Observable} from 'rxjs/Observable';
import {Observer} from 'rxjs/Observer';
import 'rxjs/add/operator/share';
import 'rxjs/add/operator/map';
import 'rxjs/Rx';

import { of } from 'rxjs/observable/of';

import { NgForm } from '@angular/forms/src/directives/ng_form';

//services en angular
import {SendEmailService} from '../../services/send-email.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html'
})
export class ContactoComponent implements OnInit {
  vistaApartado:boolean = false;
  apartado: string = "";
  texto: any[];

  public enviado = false;
  mensajeEnvio: string;

  // para el formulario
  email = new FormControl('', [Validators.required, Validators.email]);
  ContactModel = new ContactForm();
  private emailRespose;
  private truefalse:boolean = false;
  aviso:boolean = false;




  constructor(public _datosService:DatosService, private activatedRoute:ActivatedRoute, private router: Router,  private sendServices: SendEmailService,  private renderer2:Renderer2, @Inject(DOCUMENT) private _document) {}


  ngOnInit() {
    this._datosService.ponSeccion('dinamica');
    this.vistaApartado = false;
    this.sacaApartado();

  }

  sacaApartado() {
    setTimeout(() => {
      this.vistaApartado = true;
    }, 1500);

  }

  aceptarAviso(event){
    if(this.aviso){ this.aviso = false; } else { this.aviso = true; }
  }

  getErrorMessage(){
    return this.email.hasError('required') ? 'Introduzca un correo electrónico' :
      this.email.hasError('email') ? 'Correo inválido':
        '';
  }

  onSubmit(f: NgForm){
    //this.getSentServices(this.ContactModel, f);

    this._datosService.sendFormulario(this.ContactModel).subscribe(datos => {
      if (datos.status === 'success') {
        this.enviado = true;
        this.mensajeEnvio = "<h1>Gracias por contactar con nosotros</h1> <br/> EN BREVE NOS PONDREMOS EN CONTACTO CONTIGO";

        const s = this.renderer2.createElement('script');
        s.type = 'text/javascript';
        s.text = "<script>gtag('event', 'conversion', {'send_to': 'AW-472024470/lLLFCLqDhusBEJaLiuEB'});</script>";
        this.renderer2.appendChild(this._document.head, s);

        f.reset();
      }
      else {
        this.enviado = true;
        this.mensajeEnvio = "<h2>Ha ocurrido un error de conexión, por favor inténtelo más tarde.</h2>";

      }
    });

  }

  getSentServices(body:ContactForm, f: NgForm){
    this.sendServices.getResponseEmail(body)
      .subscribe(
          data => {
              if(data){
                  this.enviado = true;
                  this.mensajeEnvio = "<h1>Gracias por contactar con nosotros</h1> <br/> EN BREVE NOS PONDREMOS EN CONTACTO CONTIGO";

                  f.reset();
              }
              else{
                this.enviado = true;
                this.mensajeEnvio = "<h2>Ha ocurrido un error de conexión, por favor inténtelo más tarde.</h2>";

              }
          },
          err => {

            console.log(err);
          }
      );
  }



/*
  $scope.enviarFormulario = function (formulario) {
    Restangular.all('formularios/contacto/').post(formulario).then(function (data) {
      if (data.status === 'success') {
        $scope.resetFormulario();
      }
    toaster.pop({
            type: data.status,
            title: data.mensaje,
            timeout: 5000
        });
    }, function (data) {
      console.log(data);
    });

  }
*/

}
