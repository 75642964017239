import { EventEmitter, Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { TranslateService } from 'ng2-translate';
import { Subject } from 'rxjs/Subject';
import * as Rx from 'rxjs/Rx';
import { Categoria } from './../model/categoria';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})



export class DatosService {
  public urlApi:string = 'https://nsdrosario.es/api/'
  public urlNoti:string = 'https://nsdrosario.es/'

  public isLegal:boolean = false;
  public isCookies:boolean = false;
  public seccion:string;
  public datosProducto: any;
  public datosCategoria: any;

  private subject: BehaviorSubject<Categoria[]> = new BehaviorSubject([]);
  public categoria: Categoria[];
  public categoriaFiltrada:string;
  public rutaCategoria:any;
  public selectedIdioma:string = '';

  public categoriaBlog:any = '';


  //DATOS CARRITO
  public vistaMenu:boolean = false;
  public vistaSubmenu:boolean = false;
  public vistaCarrito:boolean = false;

  constructor(public http: HttpClient, private router:Router, private translate: TranslateService) {
    if(localStorage.getItem("isLegal") == "true"){
      this.isLegal = true;
    }

    if(localStorage.getItem("isCookies") == "true"){
      this.isCookies = true;
    }

    let localCategoria = localStorage.getItem("categoria");

  }

  aceptaLegal() {
    this.isLegal = true;
    localStorage.setItem("isLegal", "true");
  }

  aceptaCookies() {
    this.isCookies = true;
    localStorage.setItem("isCookies", "true");
  }


  ponSeccion(esta) {
    this.seccion = esta;
  }

  ponCategoria(esta) {
    this.rutaCategoria = esta;
  }

  getIdioma() {
   if(this.selectedIdioma == '') {
     this.selectedIdioma = this.translate.getBrowserLang();
   }
   return this.selectedIdioma;
  }


  getCampo(campo) {
   if(this.selectedIdioma == '') {
     this.selectedIdioma = this.translate.getBrowserLang();
   }
   let campoidioma = campo;
   if(this.selectedIdioma != 'es') {
     campoidioma = campo + "_" + this.selectedIdioma;
   }
   return campoidioma;
  }



    cambiaVistaMenu() {
      this.vistaMenu = !this.vistaMenu;
    }

    cambiaVistaSubmenu() {
      this.vistaSubmenu = !this.vistaSubmenu;
    }

    //FUCIONES DE CARRITO
    cambiaVistaCarrito() {
      this.vistaCarrito = !this.vistaCarrito;
    }

    getCategoria( laCategoria ) {
    let url:string = this.urlApi +`productos/categorias/` + laCategoria;
    let headers = new HttpHeaders({
    })
    return this.http.get(url, { headers: headers })
     .map((resp:any) =>{
         this.datosCategoria = resp;
         return this.datosCategoria;
     });
   }

    getProducto( elProducto ) {
    let url:string = this.urlApi +`productos/` + elProducto;
    let headers = new HttpHeaders({
    })
    return this.http.get(url, { headers: headers })
     .map((resp:any) =>{
         this.datosProducto = resp;
         return this.datosProducto;
     });
   }

   getCategorias( ) {
   let url:string = this.urlApi +`productos/categorias/`;
   let headers = new HttpHeaders({
   })
   return this.http.get(url, { headers: headers })
    .map((resp:any) =>{
        return resp;
    });
  }

  getProductos( ) {
  let url:string = this.urlApi +`productos/`;
  let headers = new HttpHeaders({
  })
  return this.http.get(url, { headers: headers })
   .map((resp:any) =>{
       return resp;
   });
 }

  getPaises( ) {
  let url:string = this.urlApi +`portes/paises`;
  let headers = new HttpHeaders({
  })
  return this.http.get(url, { headers: headers })
   .map((resp:any) =>{
       return resp;
   });
  }

  getTexto(clave ) {
  let url:string = this.urlApi + `preferencias/` + clave;
  let headers = new HttpHeaders({
  })
  return this.http.get(url, { headers: headers })
   .map((resp:any) =>{
       return resp;
   });
  }

  getCategoriaSeleccionada() {

  }

  saveCategoria(categoria) {
    localStorage.setItem("categoria", JSON.stringify(categoria));
    this.categoria = categoria;
    this.categoriaFiltrada = categoria.id;
    this.rutaCategoria = categoria.url;

  }

  checkCategoria(idCategoria) {
    let laCategoria = JSON.parse(localStorage.getItem("categoria"));

    if(laCategoria['id'] == idCategoria) {
      return true;
    }

  }

  //Blog
  getCategoriasNoticias( ) {
  let url:string = this.urlApi +`noticias/categorias/`;
  let headers = new HttpHeaders({
  })
  return this.http.get(url, { headers: headers })
   .map((resp:any) =>{
       return resp;
   });
 }

 getNoticias( ) {
   let url:string = this.urlApi +`noticias/`;
   let headers = new HttpHeaders({
   })
   return this.http.get(url, { headers: headers })
    .map((resp:any) =>{
        return resp;
    });
  }

  getNoticia( laNoticia ) {
    let url:string = this.urlApi +`noticias/` + laNoticia;
    let headers = new HttpHeaders({
    })
    return this.http.get(url, { headers: headers })
     .map((resp:any) =>{
         this.datosProducto = resp;
         return this.datosProducto;
     });
  }

  filtraCategoriaBlog(idCategoria) {
    this.categoriaBlog = idCategoria;
  }


  limpiaFiltros() {
    this.categoriaBlog = '';
  }

  //formularios

    sendFormulario(formulario) {
      let url:string = this.urlApi +`formularios/contacto/`;
      let formularioDatos = formulario;
      console.log('estoy enviando estos datos', formularioDatos);

       return this.http.post<any>(url, {nombre: formularioDatos['name'],email: formularioDatos['email'], asunto: formularioDatos['subject'], mensaje: formularioDatos['msn'], is_aviso: true})
           .map(resp => {
             console.log(resp);
               return resp;
           });
    }

    sendFormularioEmpleo(formulario) {
      let headers = new HttpHeaders({'Content-Type': 'multipart/form-data'});
      let url:string = this.urlApi +`formularios/contacto/`;
      let formularioDatos = formulario;

      console.log('estoy enviando estos datos', formularioDatos);

       return this.http.post<any>(url, {nombre: formularioDatos['name'], email: formularioDatos['email'], telefono: formularioDatos['telefono'], provincia: formularioDatos['provincia'], asunto: 'Solicitud de empleo', mensaje: formularioDatos['comentarios'], is_aviso: true, file: formularioDatos['file']})
           .map(resp => {
             console.log(resp);
               return resp;
           });
    }

}
