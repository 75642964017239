import { Component, OnInit, HostListener, Inject, ViewChild, Renderer2 } from '@angular/core';
import { DatosService } from '../../services/datos.service';
import { DOCUMENT } from '@angular/common';

import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { NgForm } from '@angular/forms/src/directives/ng_form';

@Component({
  selector: 'app-empleo2',
  templateUrl: './empleo2.component.html'
})
export class Empleo2Component implements OnInit {
  vistaApartado:boolean = false;
  apartado: string = "";
  aviso:boolean = false;
  public enviado = false;
  mensajeEnvio: string;
  fileToUpload: File = null;


  myForm:FormGroup = new FormGroup(
    {
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.email),
      telefono: new FormControl(''),
      provincia: new FormControl(''),
      comentarios: new FormControl(''),
      file: new FormControl('null'),
    }
  );

  formData:FormData = new FormData();

  constructor(public _datosService:DatosService, public fb: FormBuilder,  private renderer2:Renderer2, @Inject(DOCUMENT) private _document) {
    /*this.myForm = this.fb.group({
      name: ['', Validators.required],
      email: ['',  Validators.email],
      telefono: [''],
      provincia: [''],
      comentarios: [''],
      file: [null]
    })
    */
  }

  aceptarAviso(event){
    if(this.aviso){ this.aviso = false; } else { this.aviso = true; }
  }

  ngOnInit() {
    this._datosService.ponSeccion('dinamica');
    this.vistaApartado = false;
    this.sacaApartado();
  }

  onUpload(e) {
    const reader = new FileReader();
    const id = Math.random().toString(36).substring(2);
    const file = e.target.files[0];


    reader.readAsDataURL(file);
    reader.onload = () => {
        //this.myForm.append('file', file, file.name);

        this.myForm.get('file').setValue({
          filename: file.name,
          filetype: file.type,
          value: reader.result.split(',')[1]
        })

      };

  }


 onSubmit(f: NgForm){
   //this.getSentServices(this.ContactModel, f);

   console.log('mi formulario lleva esto', this.myForm.value);


   this._datosService.sendFormularioEmpleo(this.myForm.value).subscribe(datos => {
     if (datos.status === 'success') {
       this.enviado = true;
       this.mensajeEnvio = "<h1>Gracias por contactar con nosotros</h1> <br/> EN BREVE NOS PONDREMOS EN CONTACTO CONTIGO";
      
       const s = this.renderer2.createElement('script');
       s.type = 'text/javascript';
       s.text = "<script>gtag('event', 'conversion', {'send_to': 'AW-472024470/lLLFCLqDhusBEJaLiuEB'});</script>";
       this.renderer2.appendChild(this._document.head, s);

       //f.reset();
     }
     else {
       this.enviado = true;
       this.mensajeEnvio = "<h2>Ha ocurrido un error de conexión, por favor inténtelo más tarde.</h2>";

     }
   });
 }


  sacaApartado() {
    setTimeout(() => {
      this.vistaApartado = true;
    }, 1500);

  }

}
