import { Component, OnInit, HostListener, Inject, ViewChild } from '@angular/core';
import { DatosService } from '../../services/datos.service';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute} from '@angular/router';
import { Directive, ElementRef,Renderer} from '@angular/core';

import { FormControl, Validators, EmailValidator, FormBuilder, FormGroup} from "@angular/forms";

import { EmpleoForm } from '../../models/empleoForm';
//libreria rxjs
import {Observable} from 'rxjs/Observable';
import {Observer} from 'rxjs/Observer';
import 'rxjs/add/operator/share';
import 'rxjs/add/operator/map';
import 'rxjs/Rx';

import { of } from 'rxjs/observable/of';

import { NgForm } from '@angular/forms/src/directives/ng_form';

//services en angular
import {SendEmailService} from '../../services/send-email.service';

@Component({
  selector: 'app-empleo',
  templateUrl: './empleo.component.html'
})
export class EmpleoComponent implements OnInit {
  vistaApartado:boolean = false;
  apartado: string = "";
  texto: any[];

  public enviado = false;
  mensajeEnvio: string;

  // para el formulario
  email = new FormControl('', [Validators.required, Validators.email]);
  ContactModel = new EmpleoForm();
  private emailRespose;
  private truefalse:boolean = false;
  aviso:boolean = false;
  files: any[];

  @ViewChild('fileInput') fileInput: ElementRef;



  constructor(private fb: FormBuilder, public _datosService:DatosService, private activatedRoute:ActivatedRoute, private router: Router,  private sendServices: SendEmailService) {}


  ngOnInit() {
    this._datosService.ponSeccion('dinamica');
    this.vistaApartado = false;
    this.sacaApartado();
  }


  sacaApartado() {
    setTimeout(() => {
      this.vistaApartado = true;
    }, 1500);

  }

  aceptarAviso(event){
    if(this.aviso){ this.aviso = false; } else { this.aviso = true; }
  }

  getErrorMessage(){
    return this.email.hasError('required') ? 'Introduzca un correo electrónico' :
      this.email.hasError('email') ? 'Correo inválido':
        '';
  }

  onFileChange(event) {
    if(event.target.files.length > 0) {
      let file = event.target.files[0];
      this.files = event.target.files;
      //this.ContactModel.append('file', this.files, this.files.name);
      console.log(this.files);

    }
  }

  onSubmit(f: NgForm){
    //this.getSentServices(this.ContactModel, f);


    this.ContactModel['file'] = this.files[0];
    console.log(this.ContactModel['file'].value);

    this._datosService.sendFormularioEmpleo(this.ContactModel).subscribe(datos => {
      if (datos.status === 'success') {
        this.enviado = true;
        this.mensajeEnvio = "<h1>Gracias por contactar con nosotros</h1> <br/> EN BREVE NOS PONDREMOS EN CONTACTO CONTIGO";

        f.reset();
        this.fileInput.nativeElement.value = '';
      }
      else {
        this.enviado = true;
        this.mensajeEnvio = "<h2>Ha ocurrido un error de conexión, por favor inténtelo más tarde.</h2>";

      }
    });
  }

  getSentServices(body:EmpleoForm, f: NgForm){
    this.sendServices.getResponseEmail(body)
      .subscribe(
          data => {
              if(data){

                  this.enviado = true;

                  this.mensajeEnvio = "<h1>Gracias por contactar con nosotros</h1> <br/> EN BREVE NOS PONDREMOS EN CONTACTO CONTIGO";

                  f.reset();
              }
              else{
                this.enviado = true;
                this.mensajeEnvio = "<h2>Ha ocurrido un error de conexión, por favor inténtelo más tarde.</h2>";

              }
          },
          err => {

            console.log(err);
          }
      );
  }

}
