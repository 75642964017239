import { Component, OnInit } from '@angular/core';
import { DatosService } from '../../services/datos.service';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute} from '@angular/router';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-trabajos',
  templateUrl: './trabajos.component.html'
})
export class TrabajosComponent implements OnInit {
  vistaApartado:boolean = false;
  public productos: any[];
  public productosFiltrados: any[];
  public categorias:any[];
  public categoria:any[];
  public idCategoria: string;
  public nombreProducto: string;


  constructor(public _datosService:DatosService, private activatedRoute:ActivatedRoute, private router: Router, private meta:Meta) {
  }

  ngOnInit() {

    this.activatedRoute.url.subscribe( url=> {
      let ruta = this.activatedRoute.snapshot.url[0].path;
      this._datosService.ponSeccion(ruta);

      this._datosService.getCategorias().subscribe(datos => {
        this.categorias = datos;
        this._datosService.getProductos().subscribe(datos => {
          this.productos = datos;
          this.productos = this.productos.filter((producto)=> producto.publicado);

          //SI ESTAMOS EN UNA CATEGORIA...
          if(this.activatedRoute.snapshot.url.length > 1) {
            this.productosFiltrados = this.productos.filter((producto)=> this._datosService.checkCategoria(producto.id_categoria) );
          }
          else {
            this._datosService.saveCategoria("");
            this.productosFiltrados = this.productos;
          }

          //ORDENO POR POSITION
          this.productosFiltrados.sort((val1, val2)=> {return val2.position - val1.position});

          this.sacaApartado();
        });
      });
    });

  }

  getCategoria(id) {
    let miCategoria = this.categorias.findIndex((categoria) => categoria.id == id );

    return (this.categorias[miCategoria][this._datosService.getCampo('nombre')]);

  }

  sacaApartado() {
  setTimeout(() => {
    this.vistaApartado = true;
  }, 100);
}


}
