import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from '../app/components/home/home.component';
import { DinamicaComponent } from '../app/components/dinamica/dinamica.component';
import { InstalacionesComponent } from "../app/components/instalaciones/instalaciones.component";
import { AyudaComponent } from "../app/components/ayuda/ayuda.component";
import { BlogComponent } from "../app/components/blog/blog.component";
import { ContactoComponent } from "../app/components/contacto/contacto.component";
import { EmpleoComponent } from "../app/components/empleo/empleo.component";
import { Empleo2Component } from "../app/components/empleo2/empleo2.component";
import { AuthGuard } from '../app/guards/auth.guards';
import { Error404Component } from './components/error404/error404.component';


const app_routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'instalaciones', component: InstalacionesComponent },
  { path: 'ayuda-a-domicilio', component: AyudaComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog/:id', component: BlogComponent },
  { path: 'aviso-legal', component: DinamicaComponent },
  { path: 'politica-de-privacidad', component: DinamicaComponent },
  { path: 'politica-de-cookies', component: DinamicaComponent },
  { path: 'normas-uso-de-rrss', component: DinamicaComponent },
  { path: 'localizacion-y-contacto', component: ContactoComponent },
  { path: 'empleo', component: Empleo2Component },
  { path: 'empleo2', component: Empleo2Component },
  { path: 'error404', component: Error404Component },

  { path: '**', pathMatch: 'full', redirectTo: 'error404' }
];


export const app_routing = RouterModule.forRoot(app_routes);
